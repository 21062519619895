import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@talabk",
  encAlgorithm: "Rabbit",
});
export default new Vuex.Store({
  state: {
    connection_snack: false,
    drawer: true,
    mini: false,
    //def_path: "https://main.kalko.app",
    def_path: "http://127.0.0.1:8000",
    token: encryptStorage.getItem("token_hom_user") || null,
    hom_user: encryptStorage.getItem("hom_user") || null,
    break_point: 0,
    static_trans: {
      no_data: {
        ar: "لايوجد اي بيانات",
        he: "אין תוכן",
      },
      work_times: {
        ar: "ساعات العمل",
        he: "שעות עבודה ",
      },
      total_days: {
        ar: "اجمالي الايام",
        he: "סה״כ ימי עבודה",
      },
      count_days: {
        ar: "عدد الايام",
        he: "מספר ימים",
      },
      total_hours: {
        ar: "اجمالي الساعات",
        he: "סה״כ שעות",
      },
      count_hours: {
        ar: "عدد الساعات",
        he: "מספר שעות",
      },

      count_addition: {
        ar: "عدد البريميا",
        he: "מספר תוספות",
      },
      total_addition: {
        ar: "اجمالي البريميا",
        he: "סה״כ בונוסים",
      },
      total_payments: {
        ar: "اجمالي الدفع",
        he: "סה״כ שולם",
      },
      date: {
        ar: "التاريخ",
        he: "תאריך",
      },
      project: {
        ar: "المشروع",
        he: "פרויקט",
      },
      day: {
        ar: "يوم كامل",
        he: "יום",
      },
      hours: {
        ar: "ساعات",
        he: "שעות",
      },
      total: {
        ar: "الاجمالي",
        he: "סה״כ",
      },
      start_time: {
        ar: "بداية الوقت",
        he: "משעה",
      },
      end_time: {
        ar: "وقت النهاية",
        he: "עד שעה",
      },
      total_employees: {
        ar: "عدد الموظفين",
        he: "סה״כ עובדים",
      },
      employee: {
        ar: "الموظف",
        he: "עובד",
      },
    },
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    trans: (state) => (trans, lang) => {
      return state.static_trans[trans][lang];
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_hom_user");
      encryptStorage.removeItem("hom_user");
    },
    user(state, user) {
      state.hom_user = user;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone_number", cred.phone_number);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            encryptStorage.setItem("token_hom_user", token);
            encryptStorage.setItem("hom_user", res.data.data);
            context.commit("login", token);
            context.commit("user", res.data.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": cred.config.type
              ? cred.config.type
              : "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };
        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
  },
  modules: {},
});
