<template>
  <div class="header mb-5">
    <v-app-bar
      :app="$store.state.break_point <= 991"
      color="white"
      flat
      height="65"
    >
      <v-container class="py-0 align-center d-flex justify-space-between">
        <h1>
          <img width="100" src="@/assets/logo.png"/>
        </h1>

      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  methods: {},
};
</script>
